import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [];

export const dictionary = {
		"/(authenticated)/(admin)": [5,[2]],
		"/(authenticated)/(admin)/assets": [6,[2]],
		"/(authenticated)/(admin)/assets/teebox-groups/[group]": [8,[2]],
		"/(authenticated)/(admin)/assets/[asset]": [7,[2]],
		"/(authenticated)/(admin)/clients/[client]": [9,[2]],
		"/(authenticated)/(admin)/distributors": [10,[2]],
		"/(unauthenticated)/forgot-password": [31,[4]],
		"/(authenticated)/(admin)/gateways": [11,[2]],
		"/(unauthenticated)/login": [32,[4]],
		"/(authenticated)/(admin)/organizations": [12,[2]],
		"/(authenticated)/(admin)/organizations/[id]": [13,[2]],
		"/(authenticated)/(admin)/partners": [14,[2]],
		"/(authenticated)/(admin)/partners/[partner]": [15,[2]],
		"/(authenticated)/(admin)/partners/[partner]/clients/[client]": [16,[2]],
		"/(unauthenticated)/privacy-policy": [33],
		"/(authenticated)/(admin)/qr-codes": [17,[2]],
		"/(unauthenticated)/reset-password": [34,[4]],
		"/(unauthenticated)/rsc/forgot-password": [35,[4]],
		"/(unauthenticated)/rsc/reset-password": [36,[4]],
		"/(authenticated)/(admin)/sites": [18,[2]],
		"/(authenticated)/(admin)/sites/[site]": [19,[2]],
		"/(authenticated)/(admin)/users": [20,[2]],
		"/(authenticated)/(site)/[site]": [21,[3]],
		"/(authenticated)/(site)/[site]/assets": [22,[3]],
		"/(authenticated)/(site)/[site]/assets/teebox-groups/[group]": [24,[3]],
		"/(authenticated)/(site)/[site]/assets/[asset]": [23,[3]],
		"/(authenticated)/(site)/[site]/customers": [25,[3]],
		"/(authenticated)/(site)/[site]/customers/[customer]": [26,[3]],
		"/(authenticated)/(site)/[site]/products": [27,[3]],
		"/(authenticated)/(site)/[site]/reports": [28,[3]],
		"/(authenticated)/(site)/[site]/site-settings": [29,[3]],
		"/(authenticated)/(site)/[site]/transactions": [30,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';